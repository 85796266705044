<template>
  <div>
    <KTCodePreview v-bind:title="'Báo cáo hoa hồng NH máy'">
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col md="2">
            <b-input
              placeholder="Tìm kiếm"
              v-model.trim="apiParams.name"
              append-icon="search"
              single-line
              hide-details
              class="btn-sm"
              autofocus
              @keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col md="2">
            <date-picker
              placeholder="Từ ngày"
              class="form-control-sm"
              :config="dpConfigs.date"
              v-model="apiParams.fromDate"
            ></date-picker>
          </b-col>
          <b-col md="2">
            <date-picker
              placeholder="Đến ngày"
              class="form-control-sm"
              :config="dpConfigs.date"
              v-model="apiParams.toDate"
            ></date-picker>
          </b-col>
          <b-col md="3">
            <b-button variant="primary" size="sm" @click="onFilter()"
              >Tìm Kiếm</b-button
            >
          </b-col>
        </b-row>
        <!-- End of Header session -->

        <!-- Table List Report session -->
        <b-table
          :items="listReport"
          :fields="listReportFields"
          bordered
          hover
          :busy="onLoading"
        >
          <template v-slot:table-busy
            >0
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template v-slot:cell(createdAt)="row">
            <span class="text-center">{{ row.item.createdAt }}</span>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item @click="onClickExport(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-download"></i>
                    &nbsp; Tải
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <template>
          <b-row>
            <b-col>
              <p class="mt-3 text-dark font-weight-bold">
                Tổng:
                {{ totalItems }}
              </p>
            </b-col>
            <b-col>
              <BPaginationNavCustom
                :currentPage="page"
                :numberOfPages="totalPages"
                :totalPage="totalPages"
                :linkParams="{}"
                @page-change="onClickListReport()"
              />
            </b-col>
          </b-row>
        </template>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { BASE_URL } from '../../../utils/constants';
import { VclTable } from 'vue-content-loading';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import BPaginationNavCustom from '@/view/base/bootstrap/BPaginationNavCustom.vue';
import localData from '../../../utils/saveDataToLocal';
import axios from 'axios';
import fileDownload from '../../../utils/file-download';
import { DP_CONFIG } from '@/utils/date';
import { getSubtractDate, formatDBDate } from '../../../utils/date';

export default {
  data() {
    return {
      dpForm: {
        startDate: '',
        endDate: '',
      },
      dpConfigs: DP_CONFIG,
      onLoading: false,
      disabledDates: {},
      search: '',
      totalPages: 1,
      count: 1,
      totalItems: 0,
      page: 1,
      status: '',
      store: '',
      searchFromDay: '',
      searchToDay: '',
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      limit: 10,
      listItem: [],
      listReportFields: [
        {
          key: 'count',
          label: 'STT',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Chương trình',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'description',
          label: 'Mô tả',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
        },
        {
          key: 'startDate',
          label: 'Ngày bắt đầu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'endDate',
          label: 'Ngày kết thúc',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'actions',
          label: 'Hành Động',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
      ],
      listReport: [],
      apiParams: {
        name: '',
        fromDate: getSubtractDate(30),
        toDate: null,
      },
    };
  },
  methods: {
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-reward-phone-report',
      });
      this.onClickListReport();
    },
    onClickListReport: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page || 1;
      const params = {
        fromDate: formatDBDate(this.apiParams.fromDate),
        toDate: formatDBDate(this.apiParams.toDate),
        limit: this.limit,
        page: this.page,
        name: this.apiParams.name,
      };
      const { data } = await ApiService.query(`reward-phone/reports`, {
        params,
      });
      const response = data.data;
      this.listReport = response.data.map((item, index) => {
        return {
          ...item,
          count: index + 1 + this.limit * (this.page - 1),
        };
      });
      this.totalPages = response.totalPage;
      this.totalItems = response.totalRow;
      this.onLoading = false;
    },
    onClickExport: async function (item) {
      const baseUrl = BASE_URL;
      const rewardProgramId = item.id;
      const url = `${baseUrl}reward-phone/reports/${rewardProgramId}`;
      const options = { url: url, method: 'GET', responseType: 'blob' };
      return axios(options)
        .then((response) => {
          const fileName = fileDownload.getFileName(response);
          fileDownload.createFile(response.data, fileName);
        })
        .catch(() => {
          this.makeToastFaile('Xuất Excel không thành công');
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Báo cáo hoa hồng NH máy' },
      { title: 'Danh sách báo cáo hoa hồng NH máy' },
    ]);
    this.page = this.$route.query.page || 1;
  },
  created() {
    this.onClickListReport();
  },
  components: {
    KTCodePreview,
    VclTable,
    BPaginationNavCustom,
  },
};
</script>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

select {
  height: 2.5em;
}

.example {
  border: 1px solid #ddd;
  margin-bottom: 2em;
}

code,
pre {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #bbb;
  display: block;
  background: #ddd;
  border-radius: 3px;
}

.settings {
  margin: 2em 0;
  border-top: 1px solid #bbb;
  background: #eee;
}

/* h5 {
  font-size: 100%;
  padding: 0;
} */

.form-group {
  margin-bottom: 1em;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.productCode:hover {
  text-decoration: underline;
}
</style>
